import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from 'src/services/api-service';
import './designdev.css';
import SearchIcon from '@mui/icons-material/Search';
import setDate from 'date-fns/esm/setDate';
import { EmojiObjectsSharp } from '@material-ui/icons';
import { toast, ToastContainer } from 'react-toastify';
import { log } from 'console';
import { successToast } from 'src/layouts/utile/toast';

interface NavigationState {
  label: string; // For the clicked label
  heading: string; // For the dynamic heading
}

interface Data1 {
  id: number;
  reqtypeid: number;
  enqno: string;
  enqdate: Date;
  type: string;
  sname: string;
  cusname: string;
  mktname?: string; // Make optional for cases where it's not needed
  req?: string; // Make optional for cases where it's not needed
  status: string;
  designstatus: string;
  mpnno?: string; // New field to include for "NewEnq_Close"
  priority: string;
  empid: number;
  empname?: string;
  drawing?: string;
}
interface DesignerCount {
  name: string;
  tasks: string;
  img: string;
}

interface Assign {
  id: number;
  teamid: number;
  empid: number;
  cby: number;
}
interface TeamEmp {
  id: number;
  teamid: number;
  empid: number;
  name: string;
}
interface LocationState1 {
  reqtypeid: number;
  id: number;
}

const DesignDev: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activePartition, setActivePartition] = useState<string | null>("");
  const [countData, setCountData] = useState([]);
  const [tableData, setTableData] = useState<Data1[]>([]);
  const [tableData1, setTableData1] = useState<Data1[]>([]);
  const [tableData2, setTableData2] = useState<Data1[]>([]);
  const [designercountData, setDesignerCountData] = useState<DesignerCount[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(0); // Adjust based on your form fields
  const location = useLocation();
  const state = location.state as LocationState1 | undefined;
  // const [assign, setAssign] = useState<Assign[]>({
  //   id: 0,
  //   teamid: 0,
  //   empid: 0
  // });
  const [assign, setAssign] = useState<Assign[]>([]);
  const [teamid, setTeamid] = useState<number>(0);
  const [empid, setEmpid] = useState<number>(0);
  const [teamemp, setTeamemp] = useState<TeamEmp[]>([]);
  const [teamempdes, setTeamempdes] = useState<TeamEmp[]>([]);
  const [allDesigners, setAllDesigners] = useState<TeamEmp[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [drawreq, setDrawreq] = useState({
    id: 0,
    name: "",
    enqno: "",
    ipno: "",
    ipdate: "",
    drawcatgid: 0,
    drawcategory: "",
    purposeid: 0,
    drawreqdate: "",
    proname: "",
    eqptsize: "",
    eqptwt: "",
    noofeqpt: "",
    splacc: "",
    teststd: "",
    eqpttype: "",
    eqptsen: "",
    app: "",
    splnote: ""
  })

  const ipdate = drawreq?.ipdate
    ? drawreq.ipdate.split('T')[0].split('-').reverse().join('-') === '01-01-1900'
      ? ''
      : drawreq.ipdate.split('T')[0].split('-').reverse().join('-')
    : '';

  const drawreqdate = drawreq?.drawreqdate
    ? drawreq.drawreqdate.split('T')[0].split('-').reverse().join('-') === '01-01-1900'
      ? ''
      : drawreq.drawreqdate.split('T')[0].split('-').reverse().join('-')
    : '';

  useEffect(() => {
    apiService.getDropdown("dsn_mas_team").then(response => {
      setTeamemp(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [])

  const handleAssignClick = (id) => {
    apiService.getdrawreq(id)
      .then(response => {
        setDrawreq(response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));
    apiService.getlistrequirement(id)
      .then(response => {
        setTableData2(response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));

    setFormData(id);
    setOpenDialog(true);
  };

  // const handleInitiateClick = (id) => {
  //   navigate('/Design/UpdateDesign');
  // };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const data = [
    {
      label: 'New Request',
      partitions: [
        { label: 'To Be Assigned', key: 'NewEnq_Pending', className: 'last-partition' },
        { label: 'Yet to Initiate', key: 'NewEnq_Assigned', className: 'last-partition' },
        { label: 'Request to Clarify', key: 'NewEnq_Clarify', className: 'last-partition' },

      ]
    },
    {
      label: 'Concept Drawing Request',
      partitions: [
        { label: 'In Progress', key: 'NewEnq_inprogress', className: 'last-partition' },
        { label: 'Completed', key: 'NewEnq_Close', className: 'last-partition' }
      ]
    },
    {
      label: 'Engineering Change Request',
      partitions: [
        { label: 'Pending', key: 'ECR_Pending', className: 'last-partition' },
        { label: 'Request to Clarify', key: 'ECR_reqClarify', className: 'last-partition' },
        { label: 'Completed', key: 'ECR_Close', className: 'last-partition' }
      ]
    },
    {
      label: 'Production Drawing Request',
      partitions: [
        { label: 'In Progress', key: 'PDR_Pending', className: 'last-partition' },
        { label: 'Completed', key: 'PDR_Close', className: 'last-partition' }
      ]
    },
  ];

  useEffect(() => {
    apiService.getDesignCount('', localStorage.getItem('empid'))
      .then(response => {
        setCountData(response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));

    setRefresh(false)

  }, [refresh]);

  useEffect(() => {
    apiService.getDesignerTable({})
      .then(response => {
        setDesignerCountData(response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));

    setRefresh(false)
  }, [refresh]);

  const handleSubmitToClarifyFirst = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log("drawid-------", formData);
      const dataToSubmit = formData;
      const response = await apiService.req_clarifyfirst(dataToSubmit);
      console.log("id sent>>>>>>>>>>>>", response)
      console.log('Requested for Clarification', response.data);
      setOpenDialog(false);
      toast.success('Requested for Clarification');
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleSubmitAssign = async (e: React.FormEvent) => {

    e.preventDefault();
    try {

      // If validation passes, submit the data
      const dataToSubmit = assign;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postassigndata(dataToSubmit);
      setRefresh(true);
      setOpenDialog(false);
      toast.success('Assigned Successfully');
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };
  useEffect(() => {
    apiService
      .get_alldesigners() // Assuming you want to fetch based on the first element's teamid
      .then(response => {
        setAllDesigners(response.data);
        setTeamempdes(response.data);

      })
      .catch(err => console.error('Error fetching count data:', err));
  }, []);

  useEffect(() => {
    if (assign.length > 0 && assign[0].teamid > 0) {
      apiService
        .get_assign(assign[0].teamid) // Assuming you want to fetch based on the first element's teamid
        .then(response => {
          //  setTeamempdes(response.data);

        })
        .catch(err => console.error('Error fetching count data:', err));
    }
  }, [assign]);

  useEffect(() => {
    console.log("ytytyf", teamid);

    if (teamid > 0) {
      apiService
        .get_assign(teamid) // Assuming you want to fetch based on the first element's teamid
        .then(response => {
          //  setTeamempdes(response.data);

        })
        .catch(err => console.error('Error fetching count data:', err));
    }
  }, [assign, teamid]);


  useEffect(() => {
    if (activePartition.length > 0) {
      apiService
        .getDesignList(rowsPerPage.toString(), activePartition, (page + 1).toString(), search, localStorage.getItem('empid'))
        .then(response => {
          setTableData(response.data);

        })
        .catch((err) => {
          console.log("Error fetching grid data:", err);
        });

      apiService
        .getlistpending()
        .then(response => {
          setTableData1(response.data);

        })
        .catch((err) => {
          console.log("Error fetching grid data:", err);
        });
    }
    setRefresh(false)
  }, [activePartition.length, refresh]);




  const navigate = useNavigate();

  const handleLabelClick = (label: string, heading: string) => {
    navigate('/Design/UpdateDesign', { state: { label, heading } });
  };

  // Modify the click handler for labels
  const handleClick = (index: number, partitionKey: string) => {
    setActiveIndex(index);
    setActivePartition(partitionKey);
    setPage(0); // Reset page to 0 when a new partition is clicked
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    if (activePartition) {
      apiService
        .getDesignList(rowsPerPage.toString(), activePartition, (page + 1).toString(), search, localStorage.getItem('empid'))
        .then(response => {
          setTableData(response.data);
        })
        .catch((err) => {
          console.log("Error during search:", err);
        });
    } else {
      console.log("Please select a partition to search within.");
    }
  };


  const handleUpdateClick = (id: number, reqtypeid: number) => {
    // Save state to localStorage
    localStorage.setItem('activeIndex', JSON.stringify(activeIndex));
    localStorage.setItem('activePartition', activePartition);
    localStorage.setItem('tableData', JSON.stringify(tableData));
    localStorage.setItem('page', JSON.stringify(page));
    localStorage.setItem('rowsPerPage', JSON.stringify(rowsPerPage));

    // Determine the heading based on activeIndex
    let heading = '';
    if (activeIndex === 0) heading = 'Concept Drawing';
    else if (activeIndex === 1) heading = 'Engineering Change';
    else if (activeIndex === 2) heading = 'Production Drawing';

    // Navigate with the state
    navigate('/Design/UpdateDesign', { state: { id, reqtypeid, heading } });
  };

  const handleReportClick = (id: number, reqtypeid: number) => {
    navigate('/Design/CompletedReports', { state: { id, reqtypeid } });
  };

  const handleViewClick = (id: number, reqtypeid: number) => {
    navigate('/Design/ViewUpdatePage', { state: { id, reqtypeid } });
  };

  // const handleTeamChanges = (event: any) => {
  //   const { name, value } = event.target;

  //   setAssign((prevAssign) => ({
  //     ...prevAssign,
  //     id: formData,
  //     [name]: value
  //   }));
  // };

  const handleTeamChanges = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowId: number) => {
    const { name, value } = event.target;
    // If the team is selected, filter the designers
    if (name === "teamid") {
      const selectedTeamDesigners = allDesigners.filter(
        (designer) => designer.teamid === Number(value)
      );
      console.log(selectedTeamDesigners, "selectedTeamDesigners");

      // setTeamempdes(selectedTeamDesigners); // Ensure `setTeamEmpDes` updates the state
    }

    setAssign((prevAssign) => {
      // const updatedAssign = prevAssign.map((row) => {
      //   if (row.id === rowId) {
      //     // Reset empid when team changes
      //     return {
      //       ...row,
      //       [name]: Number(value),
      //       empid: 0, // Reset designer
      //     };
      //   }
      //   return row;
      // });
      const updatedAssign = [...prevAssign];
      const existingRowIndex = updatedAssign.findIndex((a) => a.id === rowId);

      if (existingRowIndex > -1) {
        // Update the specific row
        return prevAssign.map((a, index) =>
          index === existingRowIndex
            ? { ...a, id: rowId, [name]: Number(value) }
            : a
        );
      } else {
        // Add a new row
        return [...prevAssign, { id: rowId, teamid: 0, empid: 0, cby: Number(localStorage.getItem('empid')), [name]: Number(value) }];
      }

      return updatedAssign;
    });

    // setAssign((prevAssign) => {
    //   const updatedAssign = [...prevAssign];
    //   const existingRowIndex = updatedAssign.findIndex((a) => a.id === rowId);

    //   if (existingRowIndex > -1) {
    //     // Update the specific row
    //     return prevAssign.map((a, index) =>
    //       index === existingRowIndex
    //         ? { ...a, id: rowId, [name]: Number(value) }
    //         : a
    //     );
    //   } else {
    //     // Add a new row
    //     return [...prevAssign, { id: rowId, teamid: 0, empid: 0, [name]: Number(value) }];
    //   }

    //   return updatedAssign;
    // });


  };
  const handleDesignerChanges = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number
  ) => {
    const { name, value } = event.target as HTMLInputElement; // Explicitly cast event.target

    setAssign((prevAssign) =>
      prevAssign.map((row) =>
        row.id === rowId
          ? { ...row, [name]: Number(value) } // Update empid for the specific row
          : row
      )
    );
  };

  useEffect(() => {
    if (tableData2.length > 0) {
      const initialAssign = tableData2.map((row) => ({
        id: row.id,
        teamid: 0, // Default value
        empid: 0,  // Default value
        cby: Number(localStorage.getItem('empid'))
      }));
      setAssign(initialAssign);
    }
  }, [tableData2]);

  useEffect(() => {
    console.log("assign ==== >>>> ", assign);

  }, [assign])


  const handleBulkAssign = () => {
    if (teamid > 0 && empid > 0) {
      // Map all rows to assign teamid and empid
      const cby = Number(localStorage.getItem('empid'))
      const bulkAssignData = assign.map((row) => ({
        id: row.id,
        teamid,
        empid,
        cby
      }));

      setAssign(bulkAssignData);
    } else {
      alert("Please select valid Team and Designer before assigning.");
    }
  };


  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>

      <PageTitleWrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <PageTitle
            heading="Design and Development"
            subHeading=""
          />
          <TextField
            id="search"
            name="search"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            margin="dense"
            style={{ width: '200px' }} // Adjust width as needed
            placeholder="Search"
            size="small" // Reduces the size
            variant="outlined" // Smaller variant style
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              style: { padding: '3px 10px' } // Reduces inner padding
            }}
          />
        </Box>
      </PageTitleWrapper>
      <Card sx={{ padding: '1px' }}>
        <CardContent sx={{ padding: '8px', marginBottom: '-12px' }}>
          <div className="top-row">
            {data.map((item, index) => (

              < div key={index} className="box-container" >
                <span className="box-label">{item.label}</span>
                <div className="partitions-container">
                  {item.partitions.map(partition => (
                    partition.label === "To Be Assigned" && localStorage.getItem('urole') === 'Design Team' ? (<>

                    </>) : (
                      <div
                        key={partition.key}
                        className={`box ${activeIndex === index && activePartition === partition.key ? 'active' : ''} ${partition.className || ''}`}
                        onClick={() => handleClick(index, partition.key)}
                      >
                        <span className="partition-label">{partition.label}</span>
                        <span className="partition-digit">
                          {countData.find(cd => cd.name === partition.key)?.count || 0}
                        </span>
                      </div>
                    )

                  ))}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card >
      <br />
      {
        activePartition && (
          <Grid item xs={12} className="grid-margin" style={{ paddingLeft: "13px", paddingRight: "13px" }}>
            <Paper>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start' }}>
                {/* First Table */}
                <TableContainer style={{ flex: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow className="table-head">
                        <TableCell style={{ width: '50px' }}>S.No</TableCell>
                        <TableCell>Enquiry No</TableCell>
                        {/* <TableCell>Date</TableCell> */}
                        {/* <TableCell>Type</TableCell> */}
                        <TableCell>Customer</TableCell>
                        {(activePartition === 'NewEnq_Pending') && (
                          <>
                            {/* <TableCell>MKTG Officer</TableCell> */}
                            <TableCell>Task Owner</TableCell>
                            <TableCell>Design Status</TableCell>
                            <TableCell>Action</TableCell>
                          </>
                        )}
                        {(activePartition === 'ECR_Pending') && (
                          <>
                            {/* <TableCell>MKTG Officer</TableCell> */}
                            <TableCell>Requirement</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Status</TableCell>

                            <TableCell>Design Status</TableCell>
                            <TableCell>Action</TableCell>
                          </>
                        )}

                        {(activePartition === 'PDR_Pending' || activePartition === 'NewEnq_Assigned' || activePartition === 'NewEnq_inprogress') && (
                          <>
                            {/* <TableCell>MKTG Officer</TableCell> */}
                            <TableCell>Requirement</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Task Owner</TableCell>
                            <TableCell>Design Status</TableCell>
                            <TableCell>Action</TableCell>
                          </>
                        )}
                        {(activePartition === 'NewEnq_Close' || activePartition === 'ECR_Close' || activePartition === 'PDR_Close') && (
                          <>
                            <TableCell>MPN No</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Task Owner</TableCell>
                            <TableCell>Action</TableCell>
                            {/* <TableCell>Report</TableCell> */}
                          </>
                        )}
                        {(activePartition === 'NewEnq_Clarify' || activePartition === 'ECR_reqClarify') && (
                          <>
                            <TableCell>MKTG Officer</TableCell>
                            <TableCell>Requirement</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Task Owner</TableCell>
                            <TableCell>Action</TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    {(activePartition === 'NewEnq_Pending') ?
                      <TableBody>
                        {tableData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index) => (
                          <TableRow key={item.id}>
                            <TableCell className="small-font">{index + 1}</TableCell>
                            <TableCell >{item.enqno}</TableCell>
                            <TableCell >{item.name}</TableCell>
                            <TableCell >{item.empname}</TableCell>
                            <TableCell >{item.drawing}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAssignClick(item.id)}
                              >
                                Assign
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody> :
                      <TableBody>
                        {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index) => (
                          <TableRow key={item.id}>
                            <TableCell className="small-font">{index + 1}</TableCell>
                            <TableCell >{item.enqno}</TableCell>
                            {/* <TableCell>{item.enqdate}</TableCell> */}
                            {/* <TableCell>{item.type}</TableCell> */}
                            <TableCell>{item.cusname}</TableCell>
                            {(activePartition === 'NewEnq_Pending' || activePartition === 'ECR_Pending') && (
                              <>
                                {/* <TableCell>{item.mktname}</TableCell> */}
                                <TableCell>{item.req}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell >{item.status}</TableCell>
                                <TableCell><label style={{ backgroundColor: 'red', color: 'white', padding: '5px' }}><b>
                                  {item.designstatus}</b>
                                </label></TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAssignClick(item.id)}
                                  >
                                    Assign
                                  </Button>
                                </TableCell>
                              </>
                            )}

                            {(activePartition === 'NewEnq_Assigned') && (
                              <>
                                {/* <TableCell>{item.mktname}</TableCell> */}
                                <TableCell>{item.req}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>{item.empname}</TableCell>
                                <TableCell><label style={{ backgroundColor: 'blue', color: 'white', padding: '5px' }}><b>
                                  {item.designstatus}</b>
                                </label></TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleUpdateClick(item.id, item.reqtypeid)}
                                  >
                                    Initiate
                                  </Button>
                                </TableCell>
                              </>
                            )}

                            {(activePartition === 'NewEnq_inprogress' || activePartition === 'PDR_Pending') && (
                              <>
                                {/* <TableCell>{item.mktname}</TableCell> */}
                                <TableCell>{item.req}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>{item.empname}</TableCell>

                                {/* {item.designstatus === "Input" ? <TableCell sx={{ backgroundColor: "white", color: "red" }}>{item.designstatus}</TableCell>
                              : <TableCell sx={{ backgroundColor: "blue", color: "white" }}>{item.designstatus}</TableCell>} */}

                                <TableCell><label style={{ backgroundColor: 'green', color: 'white', padding: '5px' }}><b>
                                  {item.designstatus}</b>
                                </label></TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleUpdateClick(item.id, item.reqtypeid)}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              </>
                            )}

                            {(activePartition === 'NewEnq_Close' || activePartition === 'ECR_Close' || activePartition === 'PDR_Close') && (
                              <>
                                <TableCell>{item.mpnno}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>{item.empname}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleViewClick(item.id, item.reqtypeid)}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                                {/* <TableCell>
                                <Button
                                  variant="contained"
                                  color="warning"
                                  onClick={() => handleReportClick(item.id, item.reqtypeid)}
                                >
                                  View
                                </Button>
                              </TableCell> */}
                              </>
                            )}
                            {(activePartition === 'NewEnq_Clarify' || activePartition === 'ECR_reqClarify') && (
                              <>
                                <TableCell>{item.mktname}</TableCell>
                                <TableCell>{item.req}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>{item.empname}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleViewClick(item.id, item.reqtypeid)}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>}

                  </Table>
                </TableContainer>
                <Dialog className="dialog" open={openDialog} onClose={handleClose} maxWidth="md" >
                  <form>
                    <DialogContent>
                      <Paper>
                        {/* <TableContainer> */}

                        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                          <TableRow><TableCell colSpan={4}><b>MARKETING INPUT FORM</b></TableCell></TableRow>
                          <TableRow><TableCell colSpan={4}><strong>Customer Name : </strong>  {drawreq?.name}</TableCell></TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>From : Marketing</TableCell>
                            <TableCell colSpan={2}>To : Design</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>I/P No & Date</TableCell>
                            <TableCell>
                              <strong>{drawreq?.ipno}</strong> DT {ipdate}
                            </TableCell>
                            <TableCell>No. of Eqpts. Packed/Box</TableCell>
                            <TableCell>{drawreq?.noofeqpt}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Enq no.</TableCell>
                            <TableCell>{drawreq?.enqno}</TableCell>
                            <TableCell>Spl. Accs. (If any)</TableCell>
                            <TableCell>{drawreq?.splacc}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Drg. Category</TableCell>
                            <TableCell>{drawreq?.drawcategory}</TableCell>
                            <TableCell>Testing Standard</TableCell>
                            <TableCell>{drawreq?.teststd}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Drg. Required on</TableCell>
                            <TableCell>{drawreqdate}</TableCell>
                            <TableCell>Eqpt. Type</TableCell>
                            <TableCell>{drawreq?.eqpttype}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Project Name</TableCell>
                            <TableCell>{drawreq?.proname}</TableCell>
                            <TableCell>Eqpt. Sensitivity</TableCell>
                            <TableCell>{drawreq?.eqptsen}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Eqpt. Size (LBH)</TableCell>
                            <TableCell>{drawreq?.eqptsize}</TableCell>
                            <TableCell>Application</TableCell>
                            <TableCell>{drawreq?.app}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Eqpt. Wt.</TableCell>
                            <TableCell>{drawreq?.eqptwt}</TableCell>
                            <TableCell>Special note if any</TableCell>
                            <TableCell>{drawreq?.splnote}</TableCell>
                          </TableRow>
                        </Table>
                        {/* </TableContainer> */}
                      </Paper>
                      <br />
                      <span><strong>Bulk Assign</strong></span>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>S No</TableCell>
                              <TableCell>Requirement</TableCell>
                              <TableCell>Team</TableCell>
                              <TableCell>Designer</TableCell>
                              <TableCell>Bulk Assign</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>1</TableCell>
                              <TableCell>All</TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  margin="dense"
                                  value={teamid}
                                  onChange={(e) => setTeamid(Number(e.target.value))}
                                  sx={{ width: '180px', marginRight: '16px' }}
                                >
                                  <MenuItem value={0}>Select Team</MenuItem>
                                  {teamemp.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  margin="dense"
                                  value={empid}
                                  onChange={(e) => setEmpid(Number(e.target.value))}
                                  sx={{ width: '180px' }}
                                >
                                  <MenuItem value={0}>Select Designer</MenuItem>
                                  {teamempdes.filter(item => item.teamid === teamid).map((option) => (
                                    <MenuItem key={option.id} value={option.empid}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </TableCell>
                              <TableCell> <Button
                                variant="contained"
                                onClick={handleBulkAssign}
                                sx={{ marginLeft: '16px' }}
                              >
                                Bulk Assign
                              </Button></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "100vh", // Full viewport height
                          textAlign: "center", // Center text inside the Typography
                        }}
                      >
                        <Typography variant="h6">
                          --<strong>OR</strong>--
                        </Typography>
                      </div>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>S No</TableCell>
                              <TableCell>Enquiry No</TableCell>
                              <TableCell>Requirement</TableCell>
                              <TableCell>Priority</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Design Status</TableCell>
                              <TableCell>Team</TableCell>
                              <TableCell>Designer</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData2.map((item: any, index) => (
                              <TableRow key={item.id}>
                                <TableCell className="small-font">{index + 1}</TableCell>
                                <TableCell >{item.enqno}</TableCell>
                                <TableCell>{item.req}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell >{item.status}</TableCell>
                                <TableCell><label style={{ backgroundColor: 'red', color: 'white', padding: '5px' }}><b>
                                  {item.designstatus}</b>
                                </label></TableCell>
                                <TableCell>
                                  <TextField
                                    select
                                    margin="dense"
                                    name="teamid"
                                    value={assign.find((a) => a.id === item.id)?.teamid || 0}
                                    onChange={(event) => handleTeamChanges(event, item.id)}
                                    sx={{ width: '180px' }}
                                  >
                                    <MenuItem value={0}>Select Team</MenuItem>
                                    {teamemp.map((option) => (
                                      <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    select
                                    name="empid"
                                    value={assign.find((row) => row.id === item.id)?.empid || 0}
                                    onChange={(event) => handleDesignerChanges(event, item.id)}
                                    sx={{ width: "180px", marginRight: "8px" }}
                                  >
                                    <MenuItem value={0}>Select Designer</MenuItem>
                                    {teamempdes
                                      .filter((designer) => designer.teamid === assign.find((row) => row.id === item.id)?.teamid)
                                      .map((designer) => (
                                        <MenuItem key={designer.empid} value={designer.empid}>
                                          {designer.name}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                  {/* <TextField
                                  select
                                  name="empid"
                                  value={assign.find((a) => a.id === item.id)?.empid || 0}
                                   onChange={(event) => handleTeamChanges(event, item.id)}
                                  sx={{ width: '180px', marginRight: '8px' }}
                                >
                                  <MenuItem value={0}>Select Designer</MenuItem>
                                  {teamempdes.map((option) => (
                                    <MenuItem key={option.id} value={option.empid}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                  {teamempdes
                                    .filter((option) => option.teamid === item.teamid)
                                    .map((option) => (
                                      <MenuItem key={option.empid} value={option.empid}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                </TextField> */}
                                </TableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <Button color="primary" variant="contained" className="btn-print" onClick={handleSubmitToClarifyFirst}>
                        Request for Clarification
                      </Button>
                    </div> */}
                    </DialogContent>
                  </form>
                  <br />
                  {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '15px', width: '100%', padding: '0 20px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ marginRight: '-250px', minWidth: '70px', textAlign: 'right' }}>Team</span>
                    <TextField
                      select
                      margin="dense"
                      name='teamid'
                      // value={assign?.teamid}
                      // onChange={handleTeamChanges}
                      sx={{ width: '180px' }}
                    >
                      {teamemp.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <span style={{ marginRight: '-250px', minWidth: '70px', textAlign: 'right' }}>Designer</span>
                    <TextField
                      select
                      name='empid'
                      // value={assign?.empid}
                      // onChange={handleTeamChanges}
                      sx={{ width: '180px', marginRight: '8px' }}
                    >
                      {teamempdes.map((option) => (
                        <MenuItem key={option.id} value={option.empid}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div> */}
                  <br />
                  <br />
                  <DialogActions>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: '15px' }}>
                      <Button color="primary" variant="contained" className="btn-print" style={{ marginRight: '10px' }} onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" className="btn-print" onClick={handleSubmitAssign}>
                        Assign
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
                <Paper style={{ flex: 1, height: "450px" }}>
                  <TableContainer style={{ maxHeight: '390px', overflowY: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow >
                          {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell> */}
                          <TableCell style={{ padding: "10px", fontSize: "12.7", border: "0px" }}>Name</TableCell>
                          <TableCell style={{ padding: "10px", fontSize: "12.7", border: "0px" }}>Tasks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {designercountData.map((designer, index) => (
                          <TableRow key={index} style={{ borderBottom: "1px solid #eee" }}>
                            <TableCell
                              style={{
                                padding: "5px",
                                fontSize: "12.7",
                                border: "0px",
                                display: "inline-flex",
                                lineHeight: "3"
                              }}
                            >
                              <img
                                src={designer.img || 'https://image.shutterstock.com/image-vector/vector-flat-illustration-avatar-user-260nw-2503308093.jpg'} // Modify this to dynamically select the right image
                                alt="Company Logo"
                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                              />
                              <label style={{ paddingLeft: "7px" }}>{designer.name}</label>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "5px",
                                fontSize: "12.7",
                                border: "0px"
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "500",
                                  color: "#0001fd",
                                  textDecoration: "underline"
                                }}
                              >
                                {designer.tasks}
                              </label>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>

              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                component="div"
                count={tableData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        )
      }
    </>
  );
}

export default DesignDev;
